const initialState = {
  showSidebar: false,
  path: '/',
  userData: {},
  accessToken: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'AUTH':
      return { ...state, ...payload }

    case 'PATH':
      return { ...state, ...payload }
    case 'CHANGE_USER_DATA':
      return {
        ...state,
        userData: payload,
      }
    case 'CHANGE_ACCESS_TOKEN':
      return {
        ...state,
        accessToken: payload,
      }
    default:
      return state
  }
}
