export const loadStateFn = (params) => localStorage.getItem(params)

export const saveStateFn = (key, value) => localStorage.setItem(key, value)

export const clearStateFn = () => localStorage.clear()

export const removeStateFn = (key) => localStorage.removeItem(key)

export const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  return parts.length === 2 ? parts.pop().split(';').shift() : ''
}

export const deleteCookie = () => {
  const allCookies = document.cookie.split(';')

  allCookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim()
    if (cookieName.includes('_ga')) {
      deleteSelectedCookie(cookieName)
    }
  })
}

const deleteSelectedCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${window.location.host};`
}
