export const dollar = '$'

export const pound = '£'

export const zero = 0

export const bool = true

export const plans = ['Starter', 'Professional', 'Elite', 'Annualstarter']

export const dripshipper = 'dripshipper'

export const into = ' x'

export const up = 'up'

export const down = 'down'

export const cost = 'Cost'

export const difficultyOfUse = 'Difficulty of use'

export const missingFunctionality = 'Missing functionality'

export const usingOtherProduct = 'Using other product'

export const notUsingIt = 'Not using it'

export const somethingElse = '...Something else'

export const wix = 'wix'

export const bigCommerce = 'BIG_COMMERCE'

export const shopify = 'SHOPIFY'

export const incomplete = 'Incomplete'

export const complete = 'Complete'

export const token = 'token'

export const currentPlanToken = 'currentPlan'

export const welcomeSurvey = 'welcomeSurvey'

export const fullName = 'fullName'

export const reject = 'rejected'

export const plan = 'plan'

export const cancelled = 'cancelled'

export const wixPlatform = 'WIX'

export const Completed = 'Completed'

export const pending = 'pending'
