const initialBoard = {
  paymentMethodVisibility: false,
  activeComp: 'main',
  userProfile: {},
  userName: '',
  selectedPlanIndex: null,
  autoPayment: true,
  autoFulfill: true,
}

const settingsReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_CHECK_LIST_VISIBILITY':
      return {
        ...state,
        paymentMethodVisibility: action.payload,
      }
    case 'CHANGE_STEP_FOUR_ACTIVE_COMP':
      return {
        ...state,
        activeComp: action.payload,
      }
    case 'CHANGE_USER_PROFILE':
      return {
        ...state,
        userProfile: action.payload,
      }
    case 'CHANGE_USER_NAME':
      return {
        ...state,
        userName: action.payload,
      }
    case 'CHANGE_SELECTED_PLAN_INDEX':
      return {
        ...state,
        selectedPlanIndex: action.payload,
      }
    case 'CHANGE_AUTO_PAYMENT':
      return {
        ...state,
        autoPayment: action.payload,
      }
    case 'CHANGE_AUTO_SHOPIFY_FULFILL':
      return {
        ...state,
        autoFulfill: action.payload,
      }
    default:
      return { ...state }
  }
}

export default settingsReducer
