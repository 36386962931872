import React from 'react'

import logo from '../assets/images/logo.svg'
import Flex from '../shared/Flex'
import Image from '../shared/Image'
import { ternary } from '../util/javascript'

const Loader = ({ loggedIn }) => (
  <Flex
    extraClass={`fixed items-center justify-center z-50 ${ternary(
      loggedIn,
      'left-0 top-0 bottom-0 right-0 loader-logged-in',
      'inset-0 loader',
    )}`}
  >
    <div className="w-40">
      <Image extraClassName="animate-bounce w-full" src={logo} alt="logo" />
    </div>
  </Flex>
)

export default Loader
