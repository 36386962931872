const initialBoard = {
  customOptions: {},
  defaultCustomOptions: {},
  customOptionsForMyCustomProducts: {},
  customLabelImageUrl: {},
  customLabelImageId: null,
  customProductId: null,
  productData: {
    Size: '',
    Grind: '',
    CoffeeType: '',
    retail_price: '',
    description: '',
  },
  selectedLabelColor: 0,
  teaProductTitles: {},
  wholeSalePriceObj: {},
  shippingCost: {},
  paymentMethod: false,
  showDescription: false,
  customLabelFeeModelVisibility: false,
  customLabelRegion: '',
  vendorOptions: [],
  selectedVendor: '',
}

const customLabelReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_CUSTOM_OPTIONS':
      return {
        ...state,
        customOptions: action.payload,
      }
    case 'CHANGE_DEFAULT_CUSTOM_OPTIONS':
      return {
        ...state,
        defaultCustomOptions: action.payload,
      }
    case 'CUSTOM_OPTIONS_FOR_CUSTOM_PRODUCTS':
      return {
        ...state,
        customOptionsForMyCustomProducts: action.payload,
      }
    case 'CHANGE_WHOLESALE_PRICE':
      return {
        ...state,
        wholeSalePriceObj: action.payload,
      }
    case 'CHANGE_TEA_PRODUCTS_TITLES':
      return {
        ...state,
        teaProductTitles: action.payload,
      }
    case 'CHANGE_SHIPPING_COST':
      return {
        ...state,
        shippingCost: action.payload,
      }
    case 'CHANGE_CUSTOM_LABEL_IMAGE_URL':
      return {
        ...state,
        customLabelImageUrl: action.payload,
      }
    case 'CHANGE_CUSTOM_LABEL_IMAGE_ID':
      return {
        ...state,
        customLabelImageId: action.payload,
      }
    case 'CHANGE_PRODUCT_ID':
      return {
        ...state,
        customProductId: action.payload,
      }
    case 'CHANGE_SELECT_LABEL_COLOR':
      return {
        ...state,
        selectedLabelColor: action.payload,
      }
    case 'CHANGE_VIEW_PRODUCT_DATA':
      return {
        ...state,
        productData: action.payload,
      }
    case 'CHANGE_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethod: action.payload,
      }
    case 'CHANGE_SHOW_DESCRIPTION':
      return {
        ...state,
        showDescription: action.payload,
      }
    case 'CUSTOM_LABEL_REGION_CHANGE':
      return {
        ...state,
        customLabelRegion: action.payload,
      }
    case 'CUSTOM_LABEL_FEE_MODEL_VISIBILITY':
      return {
        ...state,
        customLabelFeeModelVisibility: !state.customLabelFeeModelVisibility,
      }
    case 'CHANGE_VENDOR_OPTIONS_DATA':
      return {
        ...state,
        vendorOptions: action.payload,
      }
    case 'CHANGE_SELECTED_VENDOR_DATA':
      return {
        ...state,
        selectedVendor: action.payload,
      }
    default:
      return { ...state }
  }
}

export default customLabelReducer
