import { ReactComponent as Guides } from '../../assets/icons/guides.svg'
import { ReactComponent as Logout } from '../../assets/icons/logout.svg'
import { ReactComponent as More } from '../../assets/icons/more.svg'
import { ReactComponent as Orders } from '../../assets/icons/orders.svg'
import { ReactComponent as Product } from '../../assets/icons/products.svg'
import { ReactComponent as Service } from '../../assets/icons/services.svg'
import { ReactComponent as Shop } from '../../assets/icons/shop.svg'
import { ReactComponent as Start } from '../../assets/icons/start.svg'
import { clearStateFn, deleteCookie } from '../../util/localStorage'
import { reloadFn } from '../../util/window'

export const sideBarMenuList = [
  { name: 'Getting Started', icon: Start, to: '/getting-started' },
  {
    name: 'Products',
    icon: Product,
    children: [
      { name: 'Packaging', to: '/packaging' },
      { name: 'Add Products', to: '/products' },
      { name: 'Custom Labels', to: '/custom-labels' },
      { name: 'My Custom Products', to: '/my-custom-products' },
    ],
  },
  {
    name: 'Connect Store',
    icon: Shop,
    to: '/connect-store',
  },
  {
    name: 'Get Sales',
    icon: Guides,
    to: '/get-sales',
  },
  {
    name: 'Services',
    icon: Service,
    children: [
      { name: 'Store Setup', to: '/purchase-turnkey-store' },
      { name: 'Creative Services', to: '/creative-services' },
      { name: 'Media Packages', to: '/purchase-media-packages' },
      { name: 'Transaction History', to: '/media-packages-history' },
    ],
  },
  {
    name: 'Orders',
    icon: Orders,
    children: [
      { name: 'Direct & Bulk Orders', to: '/direct-and-bulk-orders' },
      { name: 'Orders', to: '/orders' },
      { name: 'Invoices', to: '/invoices' },
    ],
  },
  {
    name: 'More',
    icon: More,
    children: [
      { name: 'Settings', to: '/settings' },
      {
        name: 'Help Center',
        to: 'https://intercom.help/dripshipper-help/en/',
        isLink: true,
      },
      {
        name: 'Privacy Policy',
        to: 'https://dripshipper.getgist.help/article/19-privacy-policy',
        isLink: true,
      },
    ],
  },
  {
    name: 'Log out',
    type: 'button',
    to: '/',
    icon: Logout,
    onClick: () => {
      clearStateFn()
      deleteCookie()
      return reloadFn()
    },
  },
]
