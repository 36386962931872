const initialBoard = {
  selectLabelCustomTabIndex: 0,
  currentLabel: 0,
  labelImgs: [],
  productsCustomTabIndex: 0,
  orderCustomTabIndex: 0,
  products: {},
  priceData: [],
  stepStatus: {},
  importedProductId: null,
  isConnected: false,
  checkListIndex: null,
  showCheckList: false,
  store_setup: {
    add_essential_page: { 1: false, 2: false, 3: false, 4: false },
    setup_website: { 1: false, 2: false },
    complete_shop: { 1: false, 2: false, 3: false },
    design_store: { 1: false, 2: false, 3: false },
    connect_domain: { 1: false },
    customer_payment: { 1: false, 2: false, 3: false },
  },
  store_setup_id: null,
  stepFourActive: 'main',
  playbook: {
    store_setup: { 1: false, 2: false, 3: false },
    products_look: { 1: false, 2: false, 3: false },
    social_media_presense: { 1: false, 2: false, 3: false },
    contact_frnds_family: { 1: false, 2: false },
  },
  playbook_id: null,
  errorToConnectStore: {},
  productSelectedLabelUrl: {},
  teaLabelUrl: {},
  viewProductDescriptionData: {},
  storePlatform: {},
  incompleteStepIndex: null,
  showPurchaseModel: true,
  showConnectStoreModel: false,
  showShopifyModel: false,
  turnkeyStoreStatus: null,
  turnkeyPurchaseModelVisibility: false,
  importingProducts: {},
  retailPriceData: {},
  openSubmenus: {},
  showRetailPriceErrorMsg: false,
  demoAppLogoId: null,
  fairtradeOneTimeCharge: false,
}

const connectStoreReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_SELECT_LABEL_CUSTOM_TAB_INDEX':
      return {
        ...state,
        selectLabelCustomTabIndex: action.payload,
      }
    case 'CHANGE_DEMO_LABEL_ID':
      return {
        ...state,
        demoAppLogoId: action.payload,
      }
    case 'CHANGE_LABEL_IMAGES':
      return {
        ...state,
        labelImgs: action.payload,
      }
    case 'CHANGE_CURRENT_LABEL':
      return {
        ...state,
        currentLabel: action.payload,
      }
    case 'CHANGE_CUSTOM_TAB_INDEX':
      return {
        ...state,
        productsCustomTabIndex: action.payload,
      }
    case 'CHANGE_ORDER_TAB_INDEX':
      return {
        ...state,
        orderCustomTabIndex: action.payload,
      }
    case 'CHANGE_SIDEBAR_SUBMENUS':
      return {
        ...state,
        openSubmenus: action.payload,
      }
    case 'CHANGE_PRODUCT_DATA':
      return {
        ...state,
        products: action.payload,
      }
    case 'CHANGE_PRICE_DATA':
      return {
        ...state,
        priceData: action.payload,
      }
    case 'CHANGE_RETAIL_PRICE_DATA':
      return {
        ...state,
        retailPriceData: action.payload,
      }
    case 'CHANGE_STEP_STATUS':
      return {
        ...state,
        stepStatus: action.payload,
      }
    case 'CHANGE_IMPORTED_PRODUCT_ID':
      return {
        ...state,
        products: action.payload,
      }
    case 'CHANGE_CHECK_LIST_INDEX':
      return {
        ...state,
        checkListIndex: action.payload,
      }
    case 'CHANGE_CHECK_LIST_VISIBILITY':
      return {
        ...state,
        showCheckList: action.payload,
      }
    case 'CHANGE_CHECK_LIST_STATUS':
      return {
        ...state,
        store_setup: action.payload,
      }
    case 'CHANGE_STORE_SETUP_ID':
      return {
        ...state,
        store_setup_id: action.payload,
      }
    case 'CHANGE_STEP_FOUR_ACTIVE_COMP':
      return {
        ...state,
        stepFourActive: action.payload,
      }
    case 'CHANGE_CHECK_LIST_STATUS_FOR_PLAYBOOK':
      return {
        ...state,
        playbook: action.payload,
      }
    case 'CHANGE_PLAYBOOK_ID':
      return {
        ...state,
        playbook_id: action.payload,
      }
    case 'CHANGE_IN_STORE_CONNECTION':
      return {
        ...state,
        isConnected: action.payload,
      }
    case 'CHANGE_IN_STORE_CONNECTION_ERROR':
      return {
        ...state,
        errorToConnectStore: action.payload,
      }
    case 'CHANGE_SELECTED_LABEL':
      return {
        ...state,
        productSelectedLabelUrl: action.payload,
      }
    case 'CHANGE_TEA_LABEL':
      return {
        ...state,
        teaLabelUrl: action.payload,
      }
    case 'CHANGE_VIEW_PRODUCT_DESCRIPTION':
      return {
        ...state,
        viewProductDescriptionData: action.payload,
      }
    case 'CHANGE_STORE_STATUS_PLATFORM':
      return {
        ...state,
        storePlatform: action.payload,
      }
    case 'CHANGE_FAIRTRADE_ONE_TIME_CHARGE':
      return {
        ...state,
        fairtradeOneTimeCharge: action.payload,
      }
    case 'CHANGE_INCOMPLETE_STEP_INDEX':
      return {
        ...state,
        incompleteStepIndex: action.payload,
      }
    case 'CHANGE_PURCHASE_MODEL_VISIBILITY':
      return {
        ...state,
        showPurchaseModel: action.payload,
      }
    case 'CHANGE_CONNECT_MODEL_VISIBILITY':
      return {
        ...state,
        showConnectStoreModel: action.payload,
      }
    case 'CHANGE_SHOPIFY_MODEL_VISIBILITY':
      return {
        ...state,
        showShopifyModel: action.payload,
      }
    case 'TURNKEY_STORE_STATUS':
      return {
        ...state,
        turnkeyStoreStatus: action.payload,
      }
    case 'CHANGE_TURNKEY_PURCHASE_MODEL_VISIBILITY':
      return {
        ...state,
        turnkeyPurchaseModelVisibility: !state.turnkeyPurchaseModelVisibility,
      }
    case 'CHANGE_IMPORTING_PRODUCT':
      return {
        ...state,
        importingProducts: action.payload,
      }
    case 'CHANGE_RETAIL_PRICE_ERROR_MSG':
      return {
        ...state,
        showRetailPriceErrorMsg: action.payload,
      }
    default:
      return { ...state }
  }
}

export default connectStoreReducer
