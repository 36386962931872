import React, { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import uid from '../shared/uid'
import WrappedComponent from '../shared/Wrapper'
import { token } from '../util/constants'
import { ternary } from '../util/javascript'
import { loadStateFn } from '../util/localStorage'

const SignUp = lazy(() => import('../containers/Signup'))
const RedirectShopifyPurchaseTurnkeyStore = lazy(() =>
  import('../containers/RedirectShopifyPurchaseTurnkeyStore'),
)
const WelcomeSurvey = lazy(() => import('../containers/Welcomesurvey'))
const PricingPlans = lazy(() => import('../containers/PricingPlans'))
const Login = lazy(() => import('../containers/Login'))
const ConnectStore = lazy(() => import('../containers/ConnectStore'))
const Success = lazy(() => import('../containers/Success'))
const SuccessForWix = lazy(() => import('../containers/SuccessForWix'))
const SuccessForInstance = lazy(() =>
  import('../containers/SuccessForInstance'),
)
const SuccessForCode = lazy(() => import('../containers/SuccessForCode'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const CustomLabels = lazy(() => import('../containers/CustomLabels'))
const MyCustomProducts = lazy(() => import('../containers/MyCustomProducts'))
const Orders = lazy(() => import('../containers/Orders'))
const Invoices = lazy(() => import('../containers/Invoices'))
const Products = lazy(() => import('../containers/Products'))
const RetailPrices = lazy(() => import('../containers/RetailPrices'))
const Guides = lazy(() => import('../containers/Guides'))
const StorePackages = lazy(() => import('../containers/StoreMediaPackages'))
const CreativeServices = lazy(() => import('../containers/CreativeServices'))
const GettingStarted = lazy(() => import('../containers/GettingStarted'))
const Packaging = lazy(() => import('../containers/Packaging'))
const PurchaseTurnkeyStore = lazy(() =>
  import('../containers/PurchaseTurnkeyStore'),
)
const MediaPackageHistory = lazy(() =>
  import('../containers/MediaPackagesHistory'),
)
const DemoApp = lazy(() => import('../containers/DemoApp'))
const BuildYourStore = lazy(() => import('../containers/BuildYourStore'))
const DirectAndBulkOrders = lazy(() =>
  import('../containers/DirectAndBulkOrders'),
)
const ResetPassword = lazy(() => import('../containers/ResetPassword'))
const ResetPasswordConfirmation = lazy(() =>
  import('../containers/ResetPasswdConfirmation'),
)
const Settings = lazy(() => import('../containers/Settings'))
const NotFound = lazy(() => import('../containers/NotFound'))

const SelectComponent = (
  Component,
  props,
  redirectUrl,
  isAuth,
  isUserLoggedIn,
) => {
  const FinalComponent = <Component {...props} />
  const FinalRedirectUrl = <Redirect to={{ pathname: redirectUrl }} />
  if (isAuth)
    return ternary(loadStateFn(token), FinalRedirectUrl, FinalComponent)
  if (isUserLoggedIn)
    return ternary(loadStateFn(token), FinalComponent, FinalRedirectUrl)
  return FinalComponent
}

const Routing = () => {
  const routes = [
    {
      path: ['/', '/sign-up', '/custom'],
      component: SignUp,
      exact: true,
      isAuth: true,
      redirectUrl: '/packaging',
      isWrapperNeeded: false,
    },
    {
      path: ['/signup1'],
      component: SignUp,
      exact: true,
      isAuth: true,
      redirectUrl: '/packaging',
      isWrapperNeeded: false,
    },
    {
      path: ['/signup2'],
      component: SignUp,
      exact: true,
      isAuth: true,
      redirectUrl: '/packaging',
      isWrapperNeeded: false,
    },
    {
      path: ['/signup3'],
      component: SignUp,
      exact: true,
      isAuth: true,
      redirectUrl: '/packaging',
      isWrapperNeeded: false,
    },
    {
      path: '/login',
      component: Login,
      isAuth: true,
      redirectUrl: '/packaging',
      isWrapperNeeded: false,
    },
    {
      path: '/forgot-password',
      component: ForgotPassword,
      isAuth: true,
      redirectUrl: '/packaging',
      isWrapperNeeded: false,
    },
    {
      path: '/reset-password',
      component: ResetPassword,
      isAuth: true,
      redirectUrl: '/packaging',
      isWrapperNeeded: false,
    },
    {
      path: '/reset-password-confirmation',
      component: ResetPasswordConfirmation,
      isAuth: true,
      redirectUrl: '/packaging',
      isWrapperNeeded: false,
    },
    {
      path: '/build-your-product',
      component: DemoApp,
      exact: true,
      isAuth: true,
      redirectUrl: '/',
      isWrapperNeeded: false,
    },
    {
      path: '/connect-store',
      component: ConnectStore,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/success',
      component: Success,
      isUserLoggedIn: false,
      redirectUrl: '/login',
      isWrapperNeeded: false,
    },
    {
      path: '/success-for-wix',
      component: SuccessForWix,
      isUserLoggedIn: false,
      redirectUrl: '/login',
      isWrapperNeeded: false,
    },
    {
      path: '/success-for-instanceId',
      component: SuccessForInstance,
      isUserLoggedIn: false,
      redirectUrl: '/login',
      isWrapperNeeded: false,
      exact: true,
    },
    {
      path: '/success-for-code',
      component: SuccessForCode,
      isUserLoggedIn: false,
      redirectUrl: '/login',
      isWrapperNeeded: false,
      exact: true,
    },
    {
      path: '/packaging',
      component: Packaging,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/products',
      component: Products,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/product-pricing',
      component: RetailPrices,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/get-sales',
      component: Guides,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/welcome-survey',
      component: WelcomeSurvey,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: false,
    },
    {
      path: '/pricing-plans',
      component: PricingPlans,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: false,
    },
    {
      path: '/settings',
      component: Settings,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/getting-started',
      component: GettingStarted,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/my-custom-products',
      component: MyCustomProducts,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/orders',
      component: Orders,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/invoices',
      component: Invoices,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/custom-labels',
      component: CustomLabels,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/direct-and-bulk-orders',
      component: DirectAndBulkOrders,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/purchase-turnkey-store',
      component: PurchaseTurnkeyStore,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/build-your-store',
      component: BuildYourStore,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/redirect-purchase-turnkey-store',
      component: RedirectShopifyPurchaseTurnkeyStore,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/purchase-media-packages',
      component: StorePackages,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/creative-services',
      component: CreativeServices,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '/media-packages-history',
      component: MediaPackageHistory,
      isUserLoggedIn: true,
      redirectUrl: '/login',
      isWrapperNeeded: true,
    },
    {
      path: '*',
      component: NotFound,
    },
  ]
  return (
    <Switch>
      {routes.map(
        ({
          component: Component,
          redirectUrl,
          isAuth,
          isUserLoggedIn,
          isWrapperNeeded,
          ...route
        }) => (
          <Route
            key={uid()}
            {...route}
            render={(props) =>
              SelectComponent(
                ternary(
                  isWrapperNeeded,
                  WrappedComponent(Component),
                  Component,
                ),
                props,
                redirectUrl,
                isAuth,
                isUserLoggedIn,
              )
            }
          />
        ),
      )}
    </Switch>
  )
}
export default Routing
