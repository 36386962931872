const initialBoard = {
  editAddressVisibility: false,
  orderSummery: [{ price: 0 }, { price: 0 }, { price: 0, bold: true }],
  productsData: [],
  productTitle: [],
  selectedProduct: '',
  allSelectedProductData: [],
  isShippingInfoExist: false,
  validateShippingCostErrorFree: true,
  shippingInfoData: {
    firstName: '',
    lastName: '',
    zipCode: '',
    country: '',
    city: '',
    state: '',
    apt: '',
    address: '',
  },
  selectLabelImageUrl: {},
}

const forgotPasswordReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_CHECK_LIST_VISIBILITY':
      return {
        ...state,
        editAddressVisibility: action.payload,
      }
    case 'CHANGE_ORDER_SUMMERY':
      return {
        ...state,
        orderSummery: action.payload,
      }
    case 'CHANGE_SHIPPING_COST_ERROR_FREE':
      return {
        ...state,
        validateShippingCostErrorFree: action.payload,
      }
    case 'CHANGE_PRODUCTS_DATA_FOR_ORDERS':
      return {
        ...state,
        productsData: action.payload,
      }
    case 'CHANGE_PRODUCTS_TITLE_DATA':
      return {
        ...state,
        productTitle: action.payload,
      }
    case 'CHANGE_SELECTED_PRODUCT':
      return {
        ...state,
        selectedProduct: action.payload,
      }
    case 'CHANGE_ALL_SELECTED_PRODUCT_DATA':
      return {
        ...state,
        allSelectedProductData: action.payload,
      }
    case 'CHANGE_SHIPPING_INFO_DETAILS':
      return {
        ...state,
        shippingInfoData: action.payload,
      }
    case 'CHANGE_SHIPPING_INFO_EXIST':
      return {
        ...state,
        isShippingInfoExist: action.payload,
      }
    case 'CHANGE_PRODUCTS_IMAGE':
      return {
        ...state,
        selectLabelImageUrl: action.payload,
      }
    default:
      return { ...state }
  }
}

export default forgotPasswordReducer
