const initialBoard = {
  allCustomProducts: [],
  productVisibility: false,
  viewProductId: null,
  showModal: false,
}

const myCustomProductsReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_ALL_CUSTOM_PRODUCTS':
      return {
        ...state,
        allCustomProducts: action.payload,
      }
    case 'CHANGE_PRODUCT_VISIBILITY':
      return {
        ...state,
        productVisibility: action.payload,
      }
    case 'CHANGE_VIEW_PRODUCT_ID':
      return {
        ...state,
        viewProductId: action.payload,
      }
    case 'CHANGE_DELETE_PRODUCT_MODAL_VISIBILITY':
      return {
        ...state,
        showModal: action.payload,
      }
    default:
      return { ...state }
  }
}

export default myCustomProductsReducer
