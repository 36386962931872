import React from 'react'
import { decodeToken } from 'react-jwt'
import { useDispatch, useSelector } from 'react-redux'

import toggler from '../../assets/icons/toggler.svg'
import logo from '../../assets/images/logo.png'
import dripshipperLogo from '../../assets/images/saasland-template-logo-dripshipper.png'
import { changeSidebarAction } from '../../redux/actions/changeSidebarAction'
import { token } from '../../util/constants'
import { loadStateFn } from '../../util/localStorage'
import { redirectFn } from '../../util/window'
import Flex from '../Flex'
import Image from '../Image'

const Header = () => {
  const dispatch = useDispatch()
  const userProfileName = useSelector((state) => state?.settings?.userName)
  const decodedToken = decodeToken(loadStateFn(token))
  const userName = userProfileName || decodedToken?.name
  const toggleHeaderButton = useSelector((state) => state.header.toggleButton)

  const handleButtonClick = () => {
    if (userName) {
      dispatch(changeSidebarAction(!toggleHeaderButton))
    } else {
      redirectFn('https://dripshipper.io')
    }
  }

  return (
    <div className="fixed top-0 left-0 right-0 shadow z-60 bg-white md:px-6 px-2 py-3">
      <Flex
        extraClass={`items-center lg:justify-between justify-${
          userName ? 'between' : 'center'
        }`}
      >
        <Flex extraClass="items-center">
          <button className="block lg:hidden mr-4" onClick={handleButtonClick}>
            <img
              src={userName ? toggler : dripshipperLogo}
              alt="Menu"
              width={userName ? 30 : 140}
            />
          </button>
          <Image
            src={logo}
            alt="logo"
            extraClassName={`object-cover w-10 h-10 hidden lg:block ${
              userName ? '' : 'cursor-pointer'
            }`}
            onClick={userName ? undefined : handleButtonClick}
          />
        </Flex>
        {userName && (
          <Flex extraClass="items-center">
            <img
              className="inline object-cover w-10 h-10 rounded-full"
              src={logo}
              alt="Profile"
            />
            <label className="overflow-hidden overflow-ellipsis whitespace-nowrap w-28">
              {userName && <span className="ml-2">{userName}</span>}
            </label>
          </Flex>
        )}
      </Flex>
    </div>
  )
}

export default Header
