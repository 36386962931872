const initialBoard = {
  id: '',
  planData: [],
  payload: {},
  promotionCodeData: {},
}

const signupReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_PLAN_ID':
      return {
        ...state,
        id: action.payload,
      }
    case 'CHANGE_PLAN_DATA':
      return {
        ...state,
        planData: action.payload,
        id: action.payload[0].id,
      }
    case 'CHANGE_MARKETING_PLAN_DATA':
      return {
        ...state,
        planData: action.payload,
        id: action.payload?.id,
      }
    case 'CHANGE_CUSTOM_PLAN_DATA':
      return {
        ...state,
        planData: action.payload,
        id: action.payload?.id,
      }
    case 'CHANGE_YEARLY_PLAN_DATA':
      return {
        ...state,
        planData: action.payload,
        id: action.payload?.id,
      }
    case 'CHANGE_SIGNUP_DETAILS':
      return {
        ...state,
        payload: action.payload,
      }
    case 'PROMOTION_CODE_DATA':
      return {
        ...state,
        promotionCodeData: action.payload,
      }
    default:
      return { ...state }
  }
}

export default signupReducer
