import { combineReducers } from 'redux'

import authReducer from './authReducer'
import connectStoreReducer from './connectStoreReducer'
import customLabelReducer from './customLabelReducer'
import directAndBulkOrderReducer from './directAndBulkOrderReducer'
import forgotPasswordReducer from './forgotPasswordReducer'
import headerReducer from './headerReducer'
import invoicesReducer from './invoicesReducer'
import loaderReducer from './loaderReducer'
import loginReducer from './loginReducer'
import mediaPackagesReducer from './mediaPackageReducer'
import myCustomProductsReducer from './myCustomProductsReducer'
import ordersReducer from './ordersReducer'
import settingsReducer from './settingsReducer'
import signupReducer from './signupReducer'
import welcomeSurveyReducer from './welcomeSurveyReducer'

export default combineReducers({
  login: loginReducer,
  forgotPassword: forgotPasswordReducer,
  signup: signupReducer,
  welcomeSurvey: welcomeSurveyReducer,
  connectStore: connectStoreReducer,
  loader: loaderReducer,
  settings: settingsReducer,
  customLabel: customLabelReducer,
  myCustomProducts: myCustomProductsReducer,
  orders: ordersReducer,
  invoices: invoicesReducer,
  directAndBulkOrder: directAndBulkOrderReducer,
  header: headerReducer,
  mediaPackage: mediaPackagesReducer,
  auth: authReducer,
})
