import React from 'react'
import { Scrollbar as SB } from 'react-scrollbars-custom'

const Scrollbar = ({ children, ...rest }) => (
  <SB noScrollX {...rest}>
    {children}
  </SB>
)

export default Scrollbar
