import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Scrollbar from 'react-scrollbars-custom'

import pushRoute from '../../hooks/use-push-route'
import { changeSidebarAction } from '../../redux/actions/changeSidebarAction'
import { changeSidebarSubMenus } from '../../redux/actions/changeSideBarSubMenus'
import { equal, ternary } from '../../util/javascript'
import Flex from '../Flex'
import uid from '../uid'
import { sideBarMenuList } from './menuItem'

const Ul = ({ mapList = [] }) => {
  const dispatch = useDispatch()
  const path = useSelector((state) => state.auth.path)
  const openSubmenus = useSelector((state) => state.connectStore?.openSubmenus)
  const { routes } = pushRoute()

  useEffect(() => {
    if (path) {
      sideBarMenuList.forEach((item) => {
        if (item.children) {
          const childItem = item.children.find((child) => child.to === path)
          if (childItem) {
            handleSubmenuClick(item.name)
          }
        }
      })
    }
  }, [])

  const handleClick = (to) => {
    dispatch(changeSidebarAction(false))
    routes(to, false)
  }
  const handleSubmenuClick = (itemName) => {
    const updatedOpenSubmenus = {
      ...openSubmenus,
      [itemName]: !openSubmenus[itemName],
    }
    dispatch(changeSidebarSubMenus(updatedOpenSubmenus))
  }

  return (
    <div className="flex grow font-medium flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-coffee px-4 text-light-coffee">
      <nav className="flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-2">
          {mapList.map((item) => (
            <li key={uid()}>
              {ternary(
                item.type,
                <div
                  className={classNames(
                    ternary(equal(path, item.to), '', 'bg-gray-50-hover'),
                    'group flex gap-x-3 rounded-md p-2 text-base leading-6 font-semibold text-white',
                  )}
                  onClick={item.onClick}
                  role="presentation"
                >
                  <item.icon
                    className="h-6 w-6 shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="">{item.name}</span>
                </div>,
                ternary(
                  !item.children,
                  <Link
                    onClick={() => handleClick(item.to)}
                    to={item.to}
                    className={classNames(
                      ternary(
                        equal(path, item.to),
                        'bg-gray-50',
                        'bg-gray-50-hover',
                      ),
                      'group flex gap-x-3 rounded-md p-2 text-base leading-6 font-semibold text-white',
                    )}
                  >
                    <item.icon
                      className="h-6 w-6 shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    {item.name}
                  </Link>,
                  <div>
                    <button
                      onClick={() => handleSubmenuClick(item.name)}
                      className={classNames(
                        'group flex items-center w-full text-left rounded-md p-2 gap-x-3 text-base leading-6 font-semibold text-white ',
                      )}
                    >
                      <item.icon
                        className="h-6 w-6 shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      {item.name}
                      <ChevronRightIcon
                        className={classNames(
                          ternary(
                            openSubmenus[item.name],
                            'rotate-90 text-white',
                            'text-gray-50 text-gray-50-hover',
                          ),
                          'h-5 w-5 shrink-0 ml-auto',
                        )}
                        aria-hidden="true"
                      />
                    </button>

                    {openSubmenus[item.name] && (
                      <ul className="mt-1 ml-1.5 px-2 flex flex-1 flex-col gap-y-1">
                        {item.children.map((subItem) => (
                          <li key={subItem.name}>
                            {ternary(
                              !subItem.isLink,
                              <Link
                                onClick={() => handleClick(subItem.to)}
                                to={subItem.to}
                                className={classNames(
                                  ternary(
                                    equal(path, subItem.to),
                                    'text-white bg-gray-50',
                                    'text-light-coffee bg-gray-50-hover',
                                  ),
                                  'block rounded-md py-2 pr-2 pl-9 text-base leading-6',
                                )}
                              >
                                {subItem.name}
                              </Link>,
                              <Disclosure
                                as="a"
                                href={subItem.to}
                                target="_blank"
                                className="block bg-gray-50-hover text-light-coffee rounded-md py-2 pr-2 pl-9 text-base leading-6 "
                              >
                                <span>{subItem.name}</span>
                              </Disclosure>,
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>,
                ),
              )}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}
const Index = () => (
  <Flex extraClass="bg-coffee w-full h-full side-nav">
    <Scrollbar>
      <div className="pt-2">
        <Ul mapList={sideBarMenuList} />
      </div>
    </Scrollbar>
  </Flex>
)

export default Index
