import { decodeToken } from 'react-jwt'

import useDispatch from './use-dispatch'

const decodeJwt = (token) => {
  const { dispatch } = useDispatch()
  const jwt = () => {
    const result = decodeToken(token)
    const updatedResult = { ...result, token }

    dispatch({
      type: 'CHANGE_USER_DATA',
      payload: updatedResult,
    })
  }
  return { jwt }
}

export default decodeJwt
