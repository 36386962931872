const initialBoard = {
  allOrdersData: [],
  pagination: {},
  modelVisibility: false,
  individualOrderData: {},
}

const ordersReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_ORDERS_DATA':
      return {
        ...state,
        allOrdersData: action.payload,
      }
    case 'CHANGE_ORDERS_PAGINATION':
      return {
        ...state,
        pagination: action.payload,
      }
    case 'CHANGE_CHECK_LIST_VISIBILITY':
      return {
        ...state,
        modelVisibility: action.payload,
      }
    case 'CHANGE_INDIVIDUAL_ORDER_DATA':
      return {
        ...state,
        individualOrderData: action.payload,
      }
    default:
      return { ...state }
  }
}

export default ordersReducer
