export const urlSearchParams = (searchParam) => ({
  searchValue: new URLSearchParams(window.location.search).get(searchParam),
  pathName: window.location.pathname,
  search: window.location.search,
})

export const { pathName } = urlSearchParams()

export const reloadFn = () => window.location.reload()

export const redirectFn = async (url) => {
  window.location.href = url
}
export const newTabFn = async (url) => {
  const newTab = window.open('', '_blank')
  if (newTab) {
    newTab.location.href = url
  } else {
    // eslint-disable-next-line no-alert
    alert('Popup blocked. Please allow popups for this site.')
    window.location.href = url
  }
}

export const encode = (param) => window.btoa(param)

export const decode = (param) => window.atob(param)
