import React, { useEffect } from 'react'
import { decodeToken } from 'react-jwt'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'

import { bigCommerce, complete, dripshipper, token } from '../../util/constants'
import { equal, lowerCase, notEqual } from '../../util/javascript'
import { loadStateFn } from '../../util/localStorage'
import Scrollbar from '../Scrollbar'
import ScrollIntoView from '../ScrollIntoView'

export default (WrappedComponent) => {
  const Hoc = ({ ...props }) => {
    const history = useHistory()
    const decodedToken = decodeToken(loadStateFn(token))
    const welcomeSurveyCompleted = equal(
      decodedToken?.survey_completed,
      complete,
    )
    const storeName = decodedToken?.store_name || ''
    const isPlatformDripshipper = equal(decodedToken?.platform, dripshipper)
    useEffect(() => {
      if (decodedToken?.platform) {
        if (
          (equal(decodedToken?.platform_plan_purchased, false) &&
            notEqual(decodedToken?.platform, dripshipper)) ||
          (isPlatformDripshipper && !decodedToken?.stripe_subscription) ||
          (equal(decodedToken?.platform, lowerCase(bigCommerce)) &&
            !decodedToken?.payment_method &&
            !decodedToken.stripe_subscription)
        ) {
          history.push('/pricing-plans')
        }
      }
    }, [decodedToken])
    useEffect(() => {
      if (!welcomeSurveyCompleted && isPlatformDripshipper) {
        history.push('/welcome-survey')
      }
    }, [welcomeSurveyCompleted])
    const toggleHeaderButton = useSelector((state) => state.header.toggleButton)
    return (
      <>
        <div className="h-screen pt-16 w-full lg:pl-60">
          <Scrollbar noScrollY={toggleHeaderButton}>
            <ScrollIntoView />
            <div className="px-4 md:px-12">
              {decodedToken?.id && decodedToken?.email && (
                <IntercomProvider
                  appId={process.env.REACT_APP_API_INTERCOM}
                  autoBoot
                  autoBootProps={{
                    actionColor: '#9C6742',
                    backgroundColor: '#9C6742',
                    name: decodedToken?.name,
                    userId: decodedToken?.id,
                    email: decodedToken?.email,
                    platform: decodedToken?.integration_platform,
                    company: {
                      companyId: decodedToken?.id,
                      createdAt: decodedToken?.created_at,
                      plan: 'Dripshipper',
                      name: storeName,
                    },
                    customAttributes: {
                      plan: decodedToken?.plan,
                      app: 'Dripshipper',
                      has_logo: decodedToken?.has_logo,
                      orders: decodedToken?.orders,
                      instance_id: decodedToken?.instance_id,
                      activated: decodedToken?.activated,
                      subscribed: decodedToken?.subscribed,
                      user_account: decodedToken?.platform,
                    },
                  }}
                >
                  <WrappedComponent {...props} />
                </IntercomProvider>
              )}
            </div>
          </Scrollbar>
        </div>
      </>
    )
  }
  return Hoc
}
