import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

const pushRoute = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const routes = (pathName, isHistory = true, historyPathname = pathName) => {
    if (isHistory) {
      history.push(historyPathname)
    }
    dispatch({
      type: 'PATH',
      payload: { path: pathName },
    })
  }
  return { routes }
}

export default pushRoute
