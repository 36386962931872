const initialBoard = {
  allMediaPackageData: [],
  pagination: {},
}

const mediaPackagesReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_MEDIA_PACKAGE_DATA':
      return {
        ...state,
        allMediaPackageData: action.payload,
      }
    case 'CHANGE_MEDIA_PACKAGE_PAGINATION':
      return {
        ...state,
        pagination: action.payload,
      }
    default:
      return { ...state }
  }
}

export default mediaPackagesReducer
