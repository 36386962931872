const initialBoard = {
  allInvoicesData: [],
  pagination: {},
  modelVisibility: false,
  individualInvoiceData: {},
  invoiceId: null,
  invoicePayVisibility: false,
}

const invoicesReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_INVOICES_DATA':
      return {
        ...state,
        allInvoicesData: action.payload,
      }
    case 'CHANGE_INVOICES_PAGINATION':
      return {
        ...state,
        pagination: action.payload,
      }
    case 'CHANGE_CHECK_LIST_VISIBILITY':
      return {
        ...state,
        modelVisibility: action.payload,
      }
    case 'CHANGE_PAY_INVOICE_VISIBILITY':
      return {
        ...state,
        invoicePayVisibility: action.payload,
      }
    case 'CHANGE_INDIVIDUAL_INVOICE_DATA':
      return {
        ...state,
        individualInvoiceData: action.payload,
      }
    case 'CHANGE_INVOICE_ID':
      return {
        ...state,
        invoiceId: action.payload,
      }
    default:
      return { ...state }
  }
}

export default invoicesReducer
