export const changeSidebarAction = (toggleButton) => (dispatch) => {
  dispatch({
    type: 'CHANGE_SIDEBAR_ACTION',
    payload: toggleButton,
  })
  dispatch({
    type: 'CHANGE_ORDER_TAB_INDEX',
    payload: 0,
  })
}
