import { useDispatch as cusDispatch } from 'react-redux'

const useDispatch = () => {
  const cDispatch = cusDispatch()
  const dispatch = (params) => {
    cDispatch(params)
  }
  return { dispatch }
}
export default useDispatch
