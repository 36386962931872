import React, { useEffect, useRef } from 'react'

const ScrollIntoView = () => {
  const ref = useRef()

  useEffect(() => {
    ref.current.scrollIntoView()
  })
  return <div ref={ref} />
}

export default ScrollIntoView
