import React from 'react'
import { useSelector } from 'react-redux'

import { ternary } from '../util/javascript'
import Loader from './Loader'

const DisplayLoader = ({ loggedIn }) => {
  const isLoading = useSelector((state) => state.loader.isLoading)

  return (
    <div style={{ display: ternary(isLoading, 'block', 'none') }}>
      <Loader loggedIn={loggedIn} />
    </div>
  )
}

export default DisplayLoader
