const initialBoard = {
  payload: {},
}

const welcomeSurveyReducer = (state = initialBoard, action) => {
  switch (action.type) {
    case 'CHANGE_WELOCOME_SURVEY_DETAILS':
      return {
        ...state,
        payload: action.payload,
      }
    default:
      return { ...state }
  }
}

export default welcomeSurveyReducer
